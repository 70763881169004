.actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;  
  margin-top: 30px;
}

.action {
  max-width: 190px;
}

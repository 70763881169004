.delimiter {
  color: var(--text-primary);
  flex: 1 1 0%;
  font: var(--font-h18sb);
  margin: 0 20px;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.control {  
  width: 50%;
}

.label {
  color: var(--primary);
  align-items: center;  
  font: var(--font-h18sb);
  display: flex;
  justify-content: center;
  height: 56px;
  width: 50%;
}
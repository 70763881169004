.header {
  grid-column: 1 / 4;
}

.content {
  grid-column: 1 / 4;
  padding: 0 calc(50% - 595px);
  padding-bottom: 43px;
}

.footer {
  grid-column: 1 / 4;
}

.title {
  align-items: center;
  display: flex;  
}

.actions {
  align-items: center;
  display: flex;
}

.action {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: normal;
  height: 48px;
  justify-content: center;
  position: relative;
  transition: var(--transition-color);
  width: 48px;
}

.action_primary {
  color: var(--primary);  
}

.action_loading {
  color: rgba(0, 0, 0, .1);
  cursor: default;
  pointer-events: none;
}

.breadcrumbs {
  color: var(--text-secondary-light);
  font: var(--font-h18);
  margin-top: -45px;
  margin-bottom: 50px;
}

.breadcrumb {
  color: inherit;  
  font: inherit;
  text-decoration: none;
  transition: var(--transition-color);
}

.breadcrumb:hover {
  color: var(--primary);
}

.breadcrumb__delimiter:last-child {
  display: none;
}

.title-edit {
  border: 1px solid var(--border-input);
  font: inherit;
  left: -5px;
  margin-bottom: -10px;
  padding: 4px;
  position: relative;
  top: -5px;
  width: 100%;
}

.title-edit_error {
  border: 1px solid var(--error);
  outline-color: var(--error);
}
.lenders {
  color: var(--text-primary);
  list-style-type: none;
  padding: 0 20px;
  line-height: 2em;
}

.lender {
  cursor: pointer;
}

.container {
  display: block;
  flex: 1;
  opacity: 0;
  overflow: hidden;
  transition: var(--transition-opacity);
  position: relative;  
}

.former {
  color: transparent;
}

.container.active {
  animation: glitch 2s infinite;
  opacity: .2;
  display: block;
  flex: 1;
  opacity: 0;
  overflow: hidden;
  transition: var(--transition-opacity);
  position: relative;  
}

.container.header {
  height: 1em;
  margin-bottom: 17px;
}

.container.paragraph {
  margin-top: 0.94em;
}

.container::before {
  background: currentColor;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, .6) 100%);
  content: "";
  display: block;;
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 1px;
  width: 100%;
}

.container::after {
  animation: shimmer 2s infinite;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  width: 200%;
}

.container.line::after {
  height: calc(100% - 2px);
  top: 1px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes glitch {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: .2;
  }
}

.container.small {
  font-size: 13px;
}

.container.big {
  font-size: 1.2rem;
}
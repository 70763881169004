.container {
  align-items: center;
  background: white;
  border-bottom: 1px solid #EAEAEA;
  display: flex;
  height: 90px;
  justify-content: space-between;
  left: 0;
  margin-bottom: 50px;
  padding: 0 calc(50% - 595px);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

.container::after {
  background: white;
  border-bottom: 1px solid #EAEAEA;
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  height: 90px;
  width: calc(100% + 20px);
  z-index: -1;
}

.logo,
.side-menus {  
  margin: 0;
}

.login {
  margin-left: auto;  
}

.menus {  
  flex-grow: 100;
}

.item {
  color: var(--text-primary);
  cursor: pointer;;
  font: var(--font-h16sb);
  margin: 0 20px;
  transition: var(--color-fg);
}

.active {
  color: var(--primary);
}

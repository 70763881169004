.container {
  margin: auto;
  max-width: 400px;
}

.title {
  color: black;
  margin-bottom: 0;
  padding: 30px;
  padding-bottom: 0;
}

.submit {
  margin: 30px;
  margin-top: 0;
  max-width: calc(100% - 60px);
}

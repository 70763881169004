.container {
  margin: 0;
  padding: 20px;
  list-style-type: none;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
}

.wrap .line {
  width: calc(50% - 5px);
}
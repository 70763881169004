.container {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.container_alternate {
  flex-direction: row-reverse;
}

.content {
  flex-basis: 890px;
  margin-right: 10px;
}

.aside {
  flex-basis: 290px;
}

.container_alternate .content {
  flex-basis: 875px;
  margin-right: 0;
  margin-left: 25px;
}
.row {
  font: var(--font-h14);
  padding: 20px;
}

.row:not(:last-child) {
  border-bottom: 1px solid var(--border-input);
}

.date {
  color: var(--text-secondary-light);  
}

.content {
  color: var(--fg-color);
}

.actions {
  border-top: 1px solid var(--border-input);
  padding: 20px;
}
.title {
  padding: 0;
  margin: 0;
  text-align: left;
}

.title-1 {
  font: var(--font-h48sb);
  margin-bottom: 50px;
}

.title-2 {
  font: var(--font-h24sb);
  margin-bottom: 15px;
}

.title-3 {
  color: var(--text-primary);
  font: var(--font-h18sb);
  margin-bottom: 15px;
}

.container {
  background-color: white;
  border: 1px solid var(--border-light);
  color: var(--fg-color);
  font: var(--font-h16sb);
  padding: 20px;
}

.link {
  align-items: center;
  color: inherit;
  display: flex;  
  justify-content: space-between;
  text-decoration: none;
}

.icon {
  flex-shrink: 0;
  margin-right: 20px;
}

.container {
  align-items: center;
  display: flex;  
}

.button {
  color: var(--text-secondary-light);
  cursor: pointer;
  padding: 0 15px;
  transition: var(--transition-fg);
}

.button:hover {
  color: var(--primary-hover);
}

.button_selected {
  color: var(--primary);
}

.link {
  color: var(--primary);
  transition: var(--transition-fg);
  text-decoration: none;
}

.link:hover {
  color: var(--primary-hover);
}

.link_disabled {
  color: var(--text-secondary-light);
}

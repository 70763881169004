:root {
  /* colors */
  --bg-color: #F8F8F8;
  --fg-color: #2B2B2B;
  --bg-input: #303034;

  --border-input: #D1D5DB;
  --border-input-focus: #9f9fa5;
  --border-input-outline: #A99986;
  --border-light: #eaeaea;
  

  --error: #E74C3C;

  --primary: #067A46;
  --primary-hover: #13AD68;
  --primary-light: #08AB62;

  --text-primary: #111827;
  --text-secondary: #6B7280;  
  --text-primary-light: white;
  --text-secondary-light: #808080;
  --text-menu: #4D625F;

  /* sizes */

  --input-padding: 15px;

  /* fonts */
  --font-h13: 500 13px/13px "Proxima Nova", sans-serif;
  --font-h14: 400 14px/17px "Proxima Nova", sans-serif;
  --font-h14sb: 600 14px/17px "Proxima Nova", sans-serif;
  --font-h16: 400 16px/19px "Proxima Nova", sans-serif;
  --font-h16sb: 600 16px/24px "Proxima Nova", sans-serif;
  --font-h18: 400 18px/24px "Proxima Nova", sans-serif;
  --font-h18l: 300 18px/25px "Proxima Nova", sans-serif;
  --font-h18sb: 600 18px/22px "Proxima Nova", sans-serif;
  --font-h20: 400 20px/24px "Proxima Nova", sans-serif;
  --font-h24b: bold 24px/29px "Proxima Nova", sans-serif;
  --font-h24sb: 600 24px/29px "Proxima Nova", sans-serif;
  --font-h48sb: 600 48px/58px "Proxima Nova", sans-serif;
  --font-input: 600 18px/25px "Proxima Nova", sans-serif;

  --opacity-loading: .4;

  /* transition */
  --transition-time: 0.3s;
  --transition-bg: background-color var(--transition-time) ease-out;
  --transition-fg: color var(--transition-time) ease-out;
  --transition-border: border-color var(--transition-time) ease-out;
  --transition-opacity: opacity var(--transition-time) ease-out;
  --transition-color: color var(--transition-time) ease-out;
  --transition-width: width var(--transition-time) ease;
  --transition-right: right var(--transition-time) ease;
  --transition-bottom: bottom var(--transition-time) ease;
  --transition-visibility: visibility var(--transition-time);
  --transition-max-height: max-height var(--transition-time) ease;

}

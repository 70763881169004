.container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;  
  padding: 20px;      
  gap: 15px;
}

.col-count_3 .tile {
  /*flex: 1 1 calc(100% / 3 - 10px);
  max-width: calc(100% / 3 - 10px);
  min-width: calc(100% / 3 - 10px);  
  width: calc(100% / 3 - 10px);  */
  flex: 1 1 calc((100% - 30px) / 3);
  max-width: calc((100% - 30px) / 3);
  min-width: calc((100% - 30px)/ 3);  
  width: calc((100% - 30px)/ 3);
}

.col-count_4 .tile {
  flex: 1 1 calc((100% - 45px) / 4);
  max-width: calc((100% - 45px) / 4);
  min-width: calc((100% - 45px) / 4); 
  width: calc((100% - 45px) / 4);
}
.content {
  position: relative;
  visibility: hidden;
}

.ready .content {
  visibility: visible;
}

.animated {
  transition: var(--transition-max-height);
}

.animated > * {
  transition: var(--transition-opacity);
}

.padding_1 {
  padding: 20px;
}

.last {
  padding-bottom: 0;
}
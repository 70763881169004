.container {
  background: white;
  box-shadow: 0px 6px 10px rgba(140, 140, 140, 0.25);
  border: 1px solid var(--primary);
  color: var(--fg-color);
  opacity: 0;
  padding: 24px 32px;
  position: absolute;
  pointer-events: none;
  transition: var(--transition-opacity);
  z-index: 10;
}

.caption {
  font: var(--font-h18sb);
  margin: 0;
  margin-bottom: 8px;
}

.content {
  font: var(--font-h14);
  margin: 0;
}

.container.visible {
  opacity: 1;
}

.container::after, .container:before {
  bottom: 100%;
  left: calc(100% - 24px);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.container::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 9px;
  margin-left: -9px;
}
.container::before {
  border-color: rgba(6, 122, 70, 0);
  border-bottom-color: #067A46;
  border-width: 10px;
  margin-left: -10px;
}
.container {
  align-items: center;
  border-bottom: 1px solid var(--border-input);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px;
}

.icon {
  margin-right: 20px;
}

.caption {
  color: var(--text-primary);  
  flex: 1;  
  margin: 0;
  padding: 0;
}

.description {
  color: var(--text-secondary);
  display: block;  
  font: var(--font-h16);
  margin-top: 5px;
}

.container.size_small {
  height: 55px;
}

.container.size_big {
  padding: 29px 30px;  
}

.container.size_small .caption {
  font: var(--font-h16sb);
}

.container.collapsed {
  border: none;
}

.action {
  flex-basis: 120px;
}

.bage {
  color: var(--primary);
}

.required-mark {
  color: var(--error);
  margin-left: 0.4em;
}

.container.has_description .caption {
  font: var(--font-h18sb);
}
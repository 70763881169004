.caption {
  color: var(--text-primary);
  font: var(--font-h24sb);
  margin-bottom: 5px;
}

.description {
  color: var(--text-secondary);
  font: var(--font-h18);
  margin-bottom: 20px;
}

.control {
  align-items: center;
  border: 1px solid var(--border-light);  
  color: var(--fg-color);
  display: flex;
  font: var(--font-h14);
  justify-content: space-between;
  height: 40px;
  padding-left: 10px;
  position: relative;
}

.control.invalid {
  border-color: var(--error);
}

.required {
  color: var(--error);
}

.field {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.icon {
  align-items: center;;
  background: rgba(6, 122, 70, 0.15);
  color: var(--primary);  
  display: flex;
  justify-content: center;
  height: 100%;
  pointer-events: none;
  position: relative;
  transition: var(--transition-bg);
  width: 38px;
}

.icon:hover {
  background: rgba(6, 122, 70, 0.3);
}

.target {
  align-items: center;
  border: 1px solid var(--border-light);  
  color: var(--text-secondary-light);
  display: flex;
  font: var(--font-h18);
  justify-content: center;
  height: 133px;
  margin-top: 14px;
  position: relative;
}

.target_active {
  background: var(--border-light);
  outline: 2px dashed var(--fg-color);
  outline-offset: 2px;
}

.drop-target-field {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

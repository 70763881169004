.control {
  background: transparent;
  border: 1px solid var(--border-input);
  color: var(--text-secondary);
  display: block;
  font: var(--font-h14);
  height: 100%;
  padding: 15px 12px;
  text-align: center;
  transition: var(--transition-border);
  width: 100%;
}

.control.error {
  border-color: var(--error);
}

.control:not(:disabled):hover {
  border-color: var(--primary-hover);
}

.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 520px;
}

.title {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding-top: 30px;
  width: 100%;
}

.action-icon {
  font-weight: 400;
  margin-right: 13px;
}

.action {
  margin-bottom: 0;
  min-width: 150px;
}

.action:not(:last-child) {
  margin-right: 10px;
}

.share {
  flex: 1 1 0px;
  width: 40px;
}

.share:not(:last-child) {
  margin-right: 10px;
}
.row {
  background-color: white;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.totals {
  background-color: white;  
  padding: 30px;
}

.inner {  
  align-items: center;;
  background-color: #f8f8f8;
  border-top: 2px solid var(--primary);
  color: var(--text-primary);
  display: flex;
  font: var(--font-h18sb);
  height: 56px;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
}

.values {
  color: var(--primary);
  min-width: 150px;
  text-align: center;
}

.or {
  font: var(--font-h18sb);
  height: 50px;
  line-height: 46px;
  margin-bottom: 30px;  
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  z-index: 1;
}
.caption {
  color: var(--text-primary);
  font: var(--font-h24sb);
  margin-bottom: 5px;
}

.description {
  color: var(--text-secondary);
  font: var(--font-h18);
  margin-bottom: 20px;
}

.control {
  align-items: center;
  border: 1px solid var(--border-light);  
  color: var(--fg-color);
  cursor: pointer;
  display: flex;
  font: var(--font-h14);
  justify-content: space-between;
  height: 40px;
  padding-left: 10px;
}

.icon {
  align-items: center;;
  background: rgba(6, 122, 70, 0.15);
  color: var(--primary);
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  transition: var(--transition-bg);
  width: 38px;
}

.icon:hover {
  background: rgba(6, 122, 70, 0.3);
}

.loading {
  pointer-events: none;
}

.error {
  color: var(--error);
  font: var(--font-h14);
  margin: 5px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.action {
  width: 190px;
}
.input {
  background: transparent;
  border: 1px solid var(--border-input);
  border-radius: 0;
  color: var(--fg-color);
  display: block;
  font: var(--font-input);
  height: 50px;
  letter-spacing: .3px;
  outline-offset: 2px;  
  padding: 0 var(--input-padding);
  position: relative;  
  transition: var(--transition-border);
  width: 100%;
  z-index: 1;
}

.input:not(:disabled):hover {
  border: 1px solid var(--primary-light);
}

.input:focus {
  border: 1px solid var(--border-input-focus);
  outline: 1px dashed var(--border-input-outline);
}

.input::placeholder {
  font-weight: 400;
}

.input.error {
  border-color: var(--error);
}
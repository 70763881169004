.title {
  font: var(--font-h48sb);
  margin: 0;
  margin-bottom: 15px;
}

.message {
  color: var(--text-secondary);
  font: var(--font-h20);
  margin-bottom: 58px;
  max-width: 370px;
}

.actions {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
}

.action {
  width: 200px;  
}

.action:not(:last-child) {
  margin-right: 15px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin-right: -10px;
}

.tile {
  margin-right: 10px;
  margin-bottom: 10px;
  width: calc((100% - 40px)/4);
}

.actions {  
  display: flex;
  justify-content: space-between;  
}

.action {
  width: 165px;
}

.action-icon {
  margin-right: 10px;
}

.lender__actions {
  display: flex;
  justify-content: flex-end;
  margin: -10px;
}

.lender__action {
  margin: 10px;
}

.container {
  padding: 20px;  
}

.caption {
  color: var(--fg-color);
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}

.bage {
  color: var(--primary);
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.button {
  min-width: 165px;
  margin-right: 10px;
}

.spacer {
  flex-grow: 1;
  width: 100%;
}

.link {
  padding: 0 10px !important;
  margin-left: 5px;
}

.icon {
  font-weight: 400;
  font-size: 20px;
  margin-right: 12px;
}

.container {
  background: white;
  border: 1px solid var(--border-light);
  color: var(--text-secondary-light);
  font: var(--font-h14);  
  padding: 15px;
  position: relative;
  transition: var(--transition-opacity);
}

.caption,
.type,
.description,
.amount,
.status {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 0 15px;
}

.key {
  flex-shrink: 0;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caption {
  color: var(--fg-color);
  font: var(--font-h16sb);
  margin: 0;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type {
  line-height: 22px;
  margin-bottom: 5px;
}

.description {
  line-height: 22px;
  margin-bottom: 5px;
}

.amount {
  line-height: 22px;
  margin-bottom: 5px;
}

.status {
  line-height: 22px;
  margin-bottom: 15px;
}

.loading {
  /*opacity: var(--opacity-loading); */
}
.sup {
  font-size: 70%;
  position: relative;
  top: -0.55em;
  vertical-align: baseline;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

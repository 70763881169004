.project-row-actions {
  position: relative;
}

.project-row-actions__action {
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  height: 40px;
  left: calc(100% + 1px);
  top: calc(50% - 20px);
  width: 25px;
}
.reject {
  width: 165px;
}

.submit-panel {
  align-items: center;
  background-color: var(--primary-light);
  color: var(--border-light);
  display: flex;
  font: var(--font-h18);
  justify-content: space-between;
  line-height: 28px;
  margin-bottom: 30px;
  padding: 20px 30px;
}

.submit-content {
  max-width: 930px;
}

.submit-title {
  color: white;
  font: var(--font-h24sb);
  margin: 0;
  margin-bottom: 5px;
}

.submit-button {
  background: white;
  border: none;
  height: 66px;
  margin: 0;
  width: 99px;
}

.alert {
  right: calc(50% - 595px);    
  position: absolute;
  /*top: 126px;    */
  top: 207px;
}

.alert_renaming {
  top: 207px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-decoration: none;
}

.icon {
  margin-left: 0;
  margin-right: 20px;
  position: relative;
  z-index: 1;
}

.container.align_right {
  flex-direction: row-reverse;
}

.container.align_right .icon {
  margin-left: 20px;
  margin-right: 0;
}

.container.type_clickable {
  cursor: pointer;
}

.container.type_clickable .icon {
  transition: var(--transition-fg), var(--transition-bg);
}

.container.type_clickable:hover .icon {
  background: var(--primary-hover);
}

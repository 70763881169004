.table {
  border-collapse: collapse;
  border-spacing: 0;
  font: var(--font-h14);
  margin-top: 10px;
  width: 100%;
}

.table tr {
  height: 54px;
}

.table th {
  color: var(--text-primary);
  font: var(--font-h14sb);
}

.table td {
  padding: 2.5px;
}

.table th:first-child {
  padding-left: 15px;
  text-align: left;
}

.table th:last-child {
  padding-right: 0;
}

.table td:first-child {
  padding-left: 0;
}

.table td:last-child {
  padding-right: 0;
}

.table > tfoot > tr > td {
  background:  #F8F8F8;
  border-top: 1px solid #E6E8F0;
}

.table .spacer {
  height: 10px;
}

.column {
  width: 120px;
}

.row-caption {
  align-items: center;
  background: rgba(221, 221, 221, 0.2);
  color: var(--text-secondary);
  display: flex;
  justify-content: flex-start;
  height: 54px;
  padding-left: 15px;
  width: 100%;
}

.row-input {
  height: 54px;
  opacity: 1;
  transition: var(--transition-opacity);
}

.per-unit {
  opacity: 1;
  text-align: center;
  transition: var(--transition-opacity);
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.total-row {
  color: var(--text-primary);
  font: var(--font-h14sb);
}

.total-row td {
  position: relative;
}

.total-cell {
  align-items: center;
  background: transparent;
  border-top: 1px solid #067A46;
  height: 54px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -1px;
  left: 2.5px;
  right: 2.5px;
}

.total-row td {
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
}

.total-row td::before {
  background: #067A46;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 2.5px;
  right: 2.5px;
}

.total-row td:first-child {
  padding-left: 15px;
  text-align: left;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;;
  padding: 30px;
}

.icon {
  margin-bottom: 15px;
}

.text {
  color: var(--text-primary);
  font: var(--font-h18);
  text-align: center;
  white-space: pre-wrap;  
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 30px;
  width: 100%;
}

.action {
  flex-grow: 1;
  margin-bottom: 0;
  min-width: 150px;
}

.action:not(:last-child) {
  margin-right: 10px;
}
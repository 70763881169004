.container {
  align-items: center;
  background: var(--primary-light);
  color: white;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.content {
  flex: 100%;
}

.action {
  background: white;
  border: none;
  color: var(--primary-light);
  font: var(--font-h16sb);
  flex: 1 1 0px;
  height: auto;
  margin: 0;
  margin-left: 20px;
  padding: 16px 24px;
  transition: var(--transition-bg);
  white-space: nowrap;
}

.action:hover {
  background: #f5f5f5;
}

.title {
  color: white;
  font: var(--font-h24sb);
  margin-bottom: 5px;
}

.sub-title {
  color: #E4E4E4;
  font: var(--font-h18);
  max-width: 570px;
}

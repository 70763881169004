.container {
  align-items: center;
  border: 1px solid var(--border-light);
  color: var(--fg-color);
  display: flex;
  flex-wrap: nowrap;
  font: var(--font-h14);
  justify-content: space-between;  
  height: 40px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

.tags {
  align-items: center;
  height: 100%;
  display: flex;
  padding-left: 2.5px;
}

.actions {  
  align-items: center;
  height: 100%;
  display: flex;
}

.tag {
  border: none;
  padding: 4px 12px;
  margin: 5px 2.5px;
}

.tag_complete,
.tag_completed {
  background: rgba(46, 204, 113, 0.15);
}

.tag_expired {
  background: rgba(231, 76, 60, 0.15);
}

.tag_active {
  background: rgba(0, 185, 171, 0.15);
}

.tag_cancelled {
  text-decoration: line-through;
}

.tag_declined {
  background: rgba(231, 76, 60, 0.15);
  text-decoration: line-through;
}

.tag_ready {
  background: rgba(243, 156, 18, 0.15);
  padding-left: 42px;
  position: relative;
}

.tag_ready::before,
.tag_ready::after
 {  
  align-items: center;
  content: "";
  display: flex;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.tag_ready::before {
  background: white;
}

.tag_ready::after {
  background: rgba(6, 122, 70, 0.15);
  background-image: url(./ready.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.link {
  align-items: center;
  background: rgba(6, 122, 70, 0.15);
  color: var(--primary);
  display: flex;
  justify-content: center;
  height: 38px;
  text-decoration: none;
  transition: var(--transition-bg);
  width: 38px;
}

.link:hover {
  background: rgba(6, 122, 70, 0.3);
}
.container, .row {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: nowrap;  
  margin-right: -10px;
}

.group {
  flex: 1;
  margin-right: 10px;
}

.rows {
  display: block;
  margin: 0;
}

.padding_1 {
  padding: 0 20px;
}

.no-title_padding_1 {
  padding-top: 20px;
}

.animated {
  transition: var(--transition-opacity);
}

.container {
  align-items: center;;
  border: 1px solid #D1D5DB;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 56px;
  justify-content: flex-start;
  padding: 0 15px;
  position: relative;
  transition: var(--transition-border);
}

.container.disabled {
  cursor: default;
}

.container.readonly {
  cursor: default;
  pointer-events: none;
}

.container.error {
  border-color: var(--error);
}

.container:not(:last-child) {
  margin-right: 20px;
}

.field {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.disabled .field {
  cursor: default;
}

.box-container { 
  height: 24px;
  margin-right: 18px;
  padding: 3px;
  width: 24px;
}

.box {
  align-items: center;
  height: 18px;
  background: transparent;
  border: 2px solid var(--text-secondary);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  transition: var(--transition-bg), var(--transition-border);
  width: 18px;
}

.mark {
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  height: 6px;
  opacity: 0;
  transform: rotate(-45deg) translateY(-1px) translateX(1px);
  transition: var(--transition-opacity);
  width: 12px;
}


.box_checked {
  background: var(--primary);
  border-color: var(--primary);
}

.box_checked .mark {
  opacity: 1;
}

.box_disabled {
  background: var(--text-secondary);
  border-color: var(--text-secondary);
}

.container:not(.disabled):hover {
  border-color: var(--primary-hover);
}

.container:not(.disabled):hover .box {
  border-color: var(--primary-hover);
}

/*.box {
  height: 24px;
  margin-right: 18px;
  width: 24px;
}*/

/*

.box_checked {
  color: var(--primary);
  height: 24px;
  margin-right: 18px;
  padding: 3px;
  width: 24px;
}

.box_disabled {
  background: var(--text-secondary);
  border-radius: 2px;
  height: 18px;
  opacity: 0;
  margin-left: 2px;
  opacity: .6;
  width: 18px;
}

.value {
  color: var(--text-secondary);
  font: var(--font-h18);
  transition: var(--color-fg);
}

.field ~ .box {
  opacity: 1;
}

.field ~ .box_checked {
  opacity: 1;
}

.field:checked ~ .box {
  opacity: 0;
}

.field:checked ~ .box_checked {
  opacity: 1;
}

.field:checked ~ .value {
  color: var(--primary);
}

.disabled .box {
  opacity: 0;
}

.disabled .box_checked {
  opacity: 0;
}

.disabled .box_disabled {
  display: initial;
}*/
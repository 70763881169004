.container {
  background: #F7E8E6;
  color: var(--error);
  font: var(--font-h18);
  margin-bottom: 30px;
  padding: 20px 30px;
}

.title {  
  font: var(--font-h24sb);
  line-height: 32px;
  margin-bottom: 5px;  
}

.item {
  line-height: 29px;
  margin-top: 5px;
}

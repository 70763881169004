.container {
  display: flex;
  justify-content: space-between; 
  padding: 15px;
}

.actions {
  display: flex;
}

.order {
  margin-right: 10px;
}

.action {
  margin-left: 10px;
  width: 165px;
}

.action-icon {
  margin-right: 10px;
}
.container {
  background: white;
  border: none;
  padding: 0;
}

.container::backdrop {
  background: rgba(0, 0, 0, .5);
}

.container[open] {
  animation: in .3s;
}

.container[open]::backdrop {
  animation: in-backdrop .3s;
}

.backdrop {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

@keyframes in-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes in {
  from {
    opacity: 0;
    transform: translateY(20px) scale(.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.container {
  border: 2px solid var(--error);
  margin-bottom: 20px;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  align-items: center;
  color: var(--text-primary);
  font: var(--font-h18);
  display: flex;
  justify-content: flex-start;
}

.item:not(:last-child) {
  margin-bottom: 15px;
}

.error {
  margin-left: 16px;
}
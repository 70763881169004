.button {
  align-items: center;;
  background: transparent;
  border: solid 1px var(--primary);
  border-radius: 0;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  font: var(--font-h16sb);
  justify-content: center;
  height: 40px;  
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  transition: var(--transition-bg), var(--transition-border), var(--transition-fg);
  margin-bottom: 20px;
  /*width: 165px;*/
  user-select: none;
}

.button.is_skeleton {
  border: none;
  padding: 0;
  pointer-events: none;
}

.skeleton {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.button.big {
  font: var(--font-h16);
  height: 50px;
  min-width: 250px;
  padding: calc((24px - .5em)) 16px;
}

.button.row {
  width: 100%;
}

.button.no-wrap {
  white-space: nowrap;
}

.button:disabled, .button.disabled {
  border-color: #6d6d75;
  color: #6d6d75;
  cursor: auto;
  pointer-events: none;
}

.button:focus {
  outline-offset: 2px;
  outline: 1px dashed var(--border-input-outline);
}

.button.loading {
  pointer-events: none;
}

.spinner {
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
}

.button:not(:disabled):hover {
  border-color: var(--primary-hover);
  color: var(--primary-hover);
}

.button.link {
  border: none;
  height: initial;
  margin-bottom: 17px;  
  padding: 0;
  transition: var(--transition-fg), var(--transition-bg);
}

.button.link:not(:disabled):hover {
  background: #F5F5F5;
  color: var(--primary);
}

.button.link.auto {
  text-align: left;
}

.button.margin-0 {
  margin-bottom: 0;
}

.button.margin-1 {
  margin-bottom: 17px;
}

.button.margin-2 {
  margin-bottom: 34px;
}

.button.margin-4 {
  margin-bottom: 64px;
}


.button.active {
  background: var(--primary);
  border-color: var(--primary);
  color: #27272b;
}

.button.active:not(:disabled):hover {
  background: transparent;
  color: #dadada;
}

.button.color_primary {
  background: #067A46;
  color: white;
}

.button:disabled, .button.disabled {
  border-color: #6d6d75;
  color: #6d6d75;
  cursor: auto;
  pointer-events: none;
}

.button.color_primary:disabled, .button.color_primary:disabled {
  background-color: #6d6d75;
  color: #e0e0e0;
}

.button.color_primary:not(:disabled):hover {
  background: var(--primary-hover);
  color: white;
}

.button.is_light {
  background: transparent;
  border: 1px solid var(--border-light);
  color: var(--text-secondary-light);
}

.button.color_primary.is_light {
  background: var(--primary-light);
  border: none;
  color: var(--text-primary-light);
}

.button.color_inline {
  background: var(--bg-color);
  border: none;
  color: var(--primary);
}

.button.inline {
  margin-right: 10px;
}

.container {
  background-color: white;
  color: var(--text-secondary);
  transition: var(--transition-opacity);
}

.color_primary {
  background-color: var(--primary);
  color: #E4E4E4;
}

.size_small {
  padding: 15px;
}

.size_medium {
  padding: 20px;
}

.size_large {
  padding: 30px;
}

.margin_medium {
  margin-bottom: 20px;
}

.margin_large {
  margin-bottom: 30px;
}

.compact {
  padding-bottom: 0;
}

.container.has_border {
  border: 1px solid #EAEAEA;
}


.is_loading {
  opacity: var(--opacity-loading);
  pointer-events: none;
  transition: none;
}

.is_flex {
  align-items: center;
  display: flex;
}
.container {    
  border-collapse: collapse;
  color: var(--text-primary);
  font: var(--font-h14);
  table-layout: fixed;  
  width: 100%;  
}

.container th {
  background: rgba(221, 221, 221, 0.2);;
}

.container th, .container td {
  border-bottom: 5px solid white;    
  height: 59px;
  padding: 0 15px;
}

.container th:not(:last-child),
.container td:not(:last-child) { 
  border-right: 5px solid white;
  height: 59px;
}

.container .caption {
  background: var(--primary);
  color: white;
  font: var(--font-h16sb);
  text-align: left;
}

.container .field-caption {
  background: rgba(221, 221, 221, 0.2);
}

.container .field-caption.total {
  position: relative;  
}

.container .field-caption.total::before {
  background: var(--primary);
  content: '';
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.field-cell {
  text-align: center;
}
.term {
  color: var(--text-secondary);
  font: var(--font-h16);
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 5px;
}

.definition {
  color: var(--text-primary);
  font: var(--font-h18);
  margin: 0;
}

.definition:not(:last-child) {
  margin-bottom: 20px;
}

.color_primary {
  color: var(--primary);
}

.error {
  color: var(--error);
}

.link {
  color: inherit;
  display: block;
  margin-bottom: -20px;
  text-decoration: none;  
  transition: var(--transition-fg);
}

.link:hover {
  color: var(--primary-hover);
}
.offer-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.offer-caption {
  align-items: center;
  display: flex;
}

.offer-actions {
  align-items: center;
  display: flex;
}

.offer-action {
  align-items: center;
  cursor: pointer;
  color: var(--fg-color);
  display: flex;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  text-decoration: none;
  transition: var(--transition-color);
}

.offer-action:hover {
  color: var(--primary-hover);
}

.offer-action-link {
  align-items: center;
  cursor: pointer;
  color: var(--primary);
  display: flex;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  text-decoration: none;
  transition: var(--transition-color);
}

.offer-action-link.offer-action_disabled {
  color: var(--fg-color);
  pointer-events: none;
}

.offer-action-link:not(.offer-action_disabled):hover {
  color: var(--primary-hover);
}

.offer-action_selected {
  color: var(--primary);
}

.deadline {
  color: var(--text-secondary-light);
  margin-left: 10px;
}

.overdue {
  color: var(--error);
  display: inline-flex;
}

.help-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.help-icon {
  flex-basis: 80px;  
  min-width: 80px;
}

.link {
  font: var(--font-h16sb);
  flex-shrink: 1;
  color: var(--fg-color);
  text-decoration: none;
}

.container {
  align-items: center;
  color: var(--text-secondary-light);  
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

.icon {
  font-size: 36px;
  /* margin-right: 20px;  */
}

.text {
  font: var(--font-h16sb);
}

.actions {
  align-items: stretch;
  background: white;
  border: 1px solid var(--border-light);
  display: flex;  
  margin-bottom: 30px;
  padding: 15px;
}

.action {
  margin-right: 10px;
  width: 165px;
}
.container {
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;  
}

.menu {
  margin-bottom: 50px;
}

.logo {
  margin: auto;
  margin-bottom: 17px;
}

.social-icons {
  align-items: center;
  display: flex;
  margin-bottom: 17px;
}
.container {
  background: white;
  border: 1px solid var(--border-input);
  height: 810px;
  margin: 0;
  max-height: 427px;
  padding: 0;
  position: relative;
  transition: var(--transition-width), var(--transition-max-height), var(---transition-opacity);
}

.loading {
  opacity: var(--opacity-loading);
  pointer-events: none;
}

.header {
  border-bottom: 1px solid var(--border-input);
  color: var(--text-primary);
  font: var(--font-h16sb);
  overflow: hidden;
  padding: 15px 64px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-title {
  cursor: pointer;
}

.icon {
  color: var(--primary);
  height: 34px;
  left: 20px;
  position: absolute;
  top: 10px;
  width: 34px;
}

.check {
  border: none;
  cursor: pointer;
  color: var(--text-secondary);  
  height: 24px;
  right: 20px;
  top: 15px;
  padding: 0;
  position: absolute;
  width: 24px;
}

.content {
  opacity: 1;
  padding: 20px;
  transition: var(--transition-opacity);
}

.full-content {
  height: calc(100% - 60px);
  opacity: 0;
  left: 0;
  padding: 20px;
  position: absolute;
  pointer-events: none;  
  top: 55px;
  transition: var(--transition-opacity);
  width: 588px;
}

.row {
  color: var(--text-primary);
  display: flex;
  font: var(--font-h14);
  justify-content: space-between;
  line-height: 22px;
  margin-bottom: 5px;
}

.row.total {
  border-top: 1px solid var(--primary);
  padding-top: 5px;
}

.row.total .caption {
  color: var(--primary);
  font: var(--font-h14sb);
}

.caption {
  color: var(--text-secondary);
}

.value {
  color: var(--text-primary);
}

.spacer {
  height: 20px;
}

.actions {
  display: flex;
  justify-content: center;
}

.state-action {
  transition: width .3s;
}

.container.expanded {
  max-height: 810px;
  width: calc(50% - 10px);
}

.expanded .state-action {
  width: 250px;
}

.expanded .actions {
  justify-content: flex-end;
}

.expanded .content {
  opacity: 0;
  pointer-events: none;
}

.expanded .full-content {
  opacity: 1;
  pointer-events: all;
}

.full-title {
  border-bottom: 1px solid var(--primary);
  color: var(--text-primary);
  font: var(--font-h16sb);
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  padding-bottom: 10px;
}

.full-actions {  
  border-top: 1px solid var(--border-input);
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  margin: 0; 
  padding: 20px;
  padding-top: 21px;
  position: absolute;  
  width: 100%;
}

.full-group {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.group-values {
  width: calc(50% - 25px);
}

.group-values-big {
  width: calc(50% + 25px);
}

.group-values-small {
  width: calc(50% - 75px);
}

.full-subtitle {
  color: var(--text-primary);
  font: var(--font-h14sb);
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

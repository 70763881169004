.container {
  background: transparent;  
  color: var(--fg-color);
  display: block;
  font: var(--font-input);  
  letter-spacing: .3px;
  position: relative;  
  width: 100%;
}

.control {
  background: transparent;
  border: 1px solid var(--border-input);
  border-radius: 0;
  color: inherit;
  display: block;
  font: inherit;
  height: 100%;
  left: 0;
  letter-spacing: inherit;
  outline-offset: 2px;
  overflow: hidden;;
  padding: 12px var(--input-padding);  
  position: absolute;
  resize: none;
  top: 0;
  transition: var(--transition-border);
  width: 100%;  
}

.control:not(:disabled):hover {
  border-color: var(--primary-hover);
}

.control:focus {
  border: 1px solid var(--border-input-focus);
  outline: 1px dashed var(--border-input-outline);
}

.error .control {
  border-color: var(--error);
}

.former {
  border: 1px solid transparent;
  display: block;
  min-height: calc(50px + 24px + 2px);
  opacity: 0;
  padding: 12px var(--input-padding);  
  pointer-events: none;
  white-space: pre-line;
  width: 100%;
}
.container {
  color: var(--primary);
  cursor: pointer;
  display: block;
  font: var(--font-h24b);
  margin: 0 auto;
  margin-left: 0;
  text-decoration: none;
  user-select: none;
}

.fund {
  color: #015F5F;
}

.keet {
  color: #067A46;
}

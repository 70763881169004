.container {
  column-count: 2;
  column-fill: auto;
  height: calc(100% + 600px);
  list-style-type: none;  
  margin: 0;
  margin-top: -330px;
  padding: 0;  
  padding-left: calc(50% - 100px);    
}

.item {
  break-inside: avoid-column;
  margin-bottom: 20px;
  width: 290px;
}

.item:first-child {
  margin-top: 150px;
}
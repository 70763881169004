.actions {
  background: white;
  border: 1px solid #EAEAEA;
  padding: 15px;
  margin-bottom: 30px;
}

.stepper {
  margin-right: 25px;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.form {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
}

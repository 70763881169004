.container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-right: -20px;
  overflow: hidden;
}

.column {
  flex: 1;
  margin-right: 20px;
}

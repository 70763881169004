.container {
  align-items: center;
  color: var(--error);
  font: var(--font-h14sb);
  gap: 15px;
}

.type_alert {
  background: rgba(231, 76, 60, 0.1);
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.caption {
  color: var(--text-primary);
  font: var(--font-h24sb);
}

.action {
  width: 190px;
}

.with_caption {
  background: white;
  padding: 30px;                
}

.action_size_medium {
  width: 130px;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 26px;  
}

.size_small {
  font-size: 22px;
  font-weight: 300;
  height: 18px;
  width: 18px;
}

.size_medium {
  font-size: 20px;
  font-weight: 400;
  height: 34px;
  width: 34px;
}

.size_large {
  font-size: 28px;
  height: 60px;
  width: 60px;
}

.size_giant {
  font-size: 38px;
  height: 80px;
  width: 80px;
}

.color_primary {
  background: var(--primary);
  border-radius: 100%;
  color: white;
}

.color_secondary {
  background: #D1D5DB;
  border-radius: 100%;
  color: white;
}

.color_error {
  color: var(--error);
}

.color_filled-error {
  background: rgba(231, 76, 60, 0.2);
  border-radius: 100%;
  color: var(--error);
}

.margin_1 {
  margin-right: 12px;
}

.margin_2 {
  margin-right: 20px;
}

.container {
  padding: 30px;
  padding-top: 10px;
}

.grid {
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
}

.grid th {
  color: var(--text-primary);
  font: var(--font-h14sb);
  height: 56px;
  text-align: center;
}

.grid th:not(:last-child),
.grid td:not(:last-child) {
  padding-right: 5px;
}

.grid tr:not(:last-child) td {
  padding-bottom: 5px;
}
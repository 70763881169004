.container {
  background: white;
  position: relative;
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--border-input);
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.icon {
  flex-shrink: 1;
  flex-grow: 0;
  margin: 0;
  margin-right: 20px;
}

.edit {
  flex-shrink: 1;
  flex-grow: 0;
  margin: 0;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.caption {
  flex-grow: 1;
  margin: 0;
}

.error::before {
  background: transparent;
  border: 2px solid #E74C3C;
  content: "";
  display: block;
  height: calc(100% - 4px);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: calc(100% - 4px);
}
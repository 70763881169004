.text {
  color: var(--primary);
  flex: 50 0 0px;
  font: var(--font-h18sb);
  height: 56px;
  line-height: 56px;
  margin-top: 27px;
  text-align: center;
}

.delimiter {  
  color: var(--text-primary);
  flex: 1 1 0px;  
  font: var(--font-h18sb);
  height: 56px;
  line-height: 56px;;
  margin: 0 20px;
  margin-top: 27px;
  text-align: center;
}
.value {  
  color: var(--text-primary);
  display: block;
  font: var(--font-h18);  
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}

.color_primary {
  color: var(--primary);
}

.clickable {
  cursor: pointer;
}

.container {
  align-items: center;
  background: rgba(221, 221, 221, 0.2);
  color: var(--text-secondary);
  display: flex;
  justify-content: space-between;
  height: 54px;
  padding: 0 15px;
  width: 100%;
}

.icon {
  cursor: pointer;
  height: 26px;
  transition: var(--transition-fg);
  width: 26px;
}

.visible {
  color: var(--primary);
}

.icon:hover {
  color: var(--primary-hover);
}
.group-title {
  padding: 0 20px;  
  margin: 0;
}

.spacer {
  height: 20px;
}

.bage {
  color: var(--primary);
}

.container {
  color: white;
  cursor: default;
  font: var(--font-h14);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: var(--transition-opacity);
  top: 0;
  width: 100%;
  user-select: none;
}

.container.active {
  opacity: 1;
  pointer-events: all;
}

.container.backdrop:before {
  background: rgba(255, 255, 255, .75);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}


.loader {
  display: inline-block;
  height: 36px;
  left: 50%;
  margin-left: -18px;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  width: 36px;
}

.loader::after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid var(--primary);
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  background: white;
  max-width: 670px;
  width: calc(100vw - 40px);
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--border-input);
  display: flex;
  justify-content: space-between;
  height: 120px;
  padding: 30px;  
  width: 100%;
}

.header__icon {
  height: 60px;
  margin-right: 20px;
  width: 60px;
}

.header__caption {
  align-items: flex-start;
  color: var(--fg-color);
  display: flex;
  justify-content: center;  
  flex: 1;
  flex-direction: column;
}

.header__name {
  display: block;  
  font: var(--font-h24sb);  
}

.header__type {
  display: block;  
  font: var(--font-h16);  
}

.header__close {
  cursor: pointer;
  height: 60px;  
  width: 60px;
}

.content {
  padding: 30px;
}

.description__title {
  color: var(--text-secondary);
  font: var(--font-h14);
  margin-bottom: 5px;
}

.description__content {
  margin-bottom: 30px;
}

.website {  
  align-items: center;
  display: flex;
}

.website__icon {
  color: var(--primary);
  margin-right: 17px;
}

.website__link {
  color: var(--text-primary);
  font: var(--font-h18);
  outline: none;
  text-decoration: none;
}

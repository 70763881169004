.container {
  align-items: center;
  color: var(--text-primary);
  display: flex;
  font: var(--font-h18sb);
  justify-content: space-between;
  position: relative;
}

.container::before {
  background-color: var(--primary);
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.caption {
  flex-grow: 1;
}

.value {
  color: var(--primary);
  flex-shrink: 1;
  min-width: 180px;
}

.padding-1 {
  padding: 30px;
}

.margin-1 {
  margin-bottom: 30px;
}

.values-right {
  text-align: right;
}

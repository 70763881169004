.container {
  border: 2px solid white;
  display: block;
  position: relative;
}

.has_error {
  border-color: var(--error);
}

.margin_1 {
  margin-bottom: 20px;
}

.margin_2 {
  margin-bottom: 40px;
}

.delimiter {
  background: #E6E8F0;
  border: none;
  height: 1px;
  margin: 0;
  margin-top: 20px;
  left: 0;
  position: absolute;
  right: 0;
}

.container.row {
  width: 100%;
}

.caption {
  align-items: center;
  color: var(--text-primary);
  display: flex;
  font: var(--font-h18sb);
  justify-content: space-between;
  margin-bottom: 8px;
  height: 34px;
}

.horz-spacer {
  flex-grow: 1;
}

.tooltip {
  color: var(--primary);
  font: var(--font-h14);
  font-size: 24px;
  line-height: 1;
  transform: rotate(180deg);
}

.description {
  color: var(--text-secondary);
  font: var(--font-h16);
}

.required-mark {
  color: var(--error);
  margin-left: .4em;
}

.control {
  margin-top: 15px;
  position: relative;
}

.control.flex {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.error {
  color: var(--error);
  display: block;
  font: var(--font-h16);
  letter-spacing: -0.1px;
  margin-top: 9px;
  white-space: pre-line;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container.align_right {
  align-items: flex-end;
}

.primary {
  color: var(--text-primary);
  font: var(--font-h18sb);
}

.required {
  color: var(--error);
}

.secondary {
  color: var(--text-secondary);
  font: var(--font-h16);
}

.size_small .primary {
  margin-bottom: 5px;
}

.size_medium .primary {
  margin-bottom: 8px;
}

.size_large .primary {
  margin-bottom: 5px;
}

.size_large .secondary {
  font: var(--font-h14);
}

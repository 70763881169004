.row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 20px;
}

.cell-50 {
  margin-left: 10px;
  margin-right: 10px;
  width: 50%;
}
.single {
  margin-bottom: 20px;
}

.list-row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.list-cell-50 {
  width: 50%;
}

.summary-delimiter {
  background: var(--border-input);
  border: 0;
  height: 1px;
  margin: 0;  
}
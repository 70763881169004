.label {
  color: var(--text-primary);
  font: var(--font-h18sb);
  height: 50px;
  line-height: 50px;
}

.color_primary {
  color: var(--primary);
}

.container {
  max-width: 325px;
  width: 100%;
}

.item:not(:last-child) {
  margin-bottom: 60px;
  position: relative;
}

.item:not(:last-child)::after {
  background-color: #D1D5DB;
  content: "";
  height: 64px;
  display: block;
  position: absolute;
  right: 29px;
  top: calc(100% - 2px);
  width: 2px;
}

.item.complete::after {
  background-color: var(--primary);
}
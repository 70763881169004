.container {
  align-items: center;
  border: 1px solid var(--border-input);
  color: var(--fg-color);
  display: flex;
  font: var(--font-h14);
  justify-content: space-between;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

.container.loading {
  border: none;
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0 10px;
}

.link {
  width: 40px;
}

.skeleton {
  height: 40px;
  width: 100%;
}

.content_closed {  
  text-decoration: line-through;
}

.content_cancelled {
  opacity: .5;
  text-decoration: line-through;
}
.key {
  color: var(--text-secondary);
  display: block;
  font: var(--font-h16);  
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

.color_primary {
  color: var(--primary);
  font-weight: 600;
}

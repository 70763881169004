@font-face {
	font-family: "Proxima Nova";
	src: url("./proxima-nova/ProximaNova-Light.woff2") format("woff2"), url("./proxima-nova/ProximaNova-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("./proxima-nova/ProximaNova-Regular.woff2") format("woff2"), url("./proxima-nova/ProximaNova-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("./proxima-nova/ProximaNova-Medium.woff2") format("woff2"), url("./proxima-nova/ProximaNova-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	ascent-override: 84%;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("./proxima-nova/ProximaNova-Semibold.woff2") format("woff2"), url("./proxima-nova/ProximaNova-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("./proxima-nova/ProximaNova-Bold.woff2") format("woff2"), url("./proxima-nova/ProximaNova-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
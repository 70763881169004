.container {
  animation: show .4s;
  display: flex;
  flex-wrap: nowrap;
  height: calc(100vh - 91px);
  left: 0;
  position: fixed;
  top: 91px;
  width: 100%;
  z-index: 10;
}

.message, .image {
  height: 100%;
  width: 50%;
}

.message {
  align-items: flex-start;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(50% - 595px);
  padding-right: 40px;
}

.image {
  background: var(--primary-light);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
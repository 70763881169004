.pre-or-container {
  padding-bottom: 0;
}

.or-container {
  background: white;
  padding: 30px;
  padding-top: 0;
}

.or-caption {
  color: var(--primary);
  font: var(--font-h18sb);
  height: 56px;
  line-height: 56px;
}
.container {
  color: inherit;
  display: block;
  padding: 0;
  text-decoration: none;  
}

.container:not(:last-child) {
  margin-right: 17px;
}

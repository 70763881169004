.container {
  align-items: center;
  color: var(--text-menu);
  font: var(--font-h16sb);
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.link {
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0 20px;
  position: relative;
  text-decoration: none;
  transition: var(--transition-color);
}

.link:hover {
  color: var(--primary);
}

.link::after {
  background: var(--primary);
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  margin-left: -18px;
  opacity: 0;
  position: absolute;
  top: calc(100% + 5px);
  transition: var(--transition-opacity);
  width: 36px;
}

.link.active {
  color: var(--primary);
}

.link.active::after {
  opacity: 1;
}
